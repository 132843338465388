<template>
  <div class="inter-wrapper">
    <HeaderInter back-path="/user/info" />
    <div class="inter-content">
      <div v-if="$store.getters.memberInfo" class="orderinit-area container-fluid">
        <div class="order-area">
          <div class="form-area mb-3">
            <input
              v-model="form.name"
              v-validate="'required'"
              type="text"
              class="box"
              placeholder="姓名"
              name="name"
              data-vv-as="姓名"
            >
            <div v-if="errors.has('name')" class="help-block">
              {{ errors.first('name') }}
            </div>

            <input
              v-model="form.phone"
              v-validate="'required'"
              type="tel"
              class="box"
              name="phone"
              placeholder="聯絡電話"
              data-vv-as="聯絡電話"
            >
            <div v-if="errors.has('phone')" class="help-block">
              {{ errors.first('phone') }}
            </div>

            <input
              v-model="form.email"
              v-validate="'required'"
              type="text"
              class="box"
              name="email"
              data-vv-as="電子信箱"
              placeholder="電子信箱"
            >
            <div v-if="errors.has('email')" class="help-block">
              {{ errors.first('email') }}
            </div>
          </div>
          <div class="btn-area px-3">
            <button class="btn btn-next" type="button" @click="submit()">
              確認送出
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderInter from '@/components/HeaderInter'
import { editMember } from '@/api/user'
import { showNotice } from '@/utils/tool'

export default {
  name: 'UserEdit',
  components: {
    HeaderInter
  },
  data() {
    const form = {
      name: null,
      email: null,
      phone: null
    }

    return {
      form: form
    }
  },
  created() {
  },
  mounted() {},
  methods: {
    submit() {
      const self = this
      this.$validator.validateAll().then(valid => {
        if (valid) {
          editMember(this.form).then(response => {
            self.$store.commit('SET_MEMBER_INFO', response.data.data)
            showNotice('資料已更新', function() {
              self.$router.push({ path: '/user/info' }).catch(() => {
              })
            })
          }).catch(() => {
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "assets/scss/common.scss";

</style>
